import React from 'react';

interface ToggleProps {
  isOn: boolean;
  isDisabled?: boolean;
  onToggle: () => void;
}

export default function Toggle({
  isOn,
  isDisabled = false,
  onToggle,
}: ToggleProps) {
  function handleOnClick() {
    if (isDisabled) {
      return;
    }

    onToggle();
  }
  return (
    <div onClick={handleOnClick}>
      <label
        className={`rounded-full w-4.5 h-2.5 flex items-center ${
          isOn ? 'bg-socialiePink' : 'bg-grey7'
        } ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <span
          className={`rounded-full w-2 h-2 transform transition-all bg-white ease-in-out duration-300 ml-0.25 ${
            isOn ? 'translate-x-2' : 'translate-0'
          }`}
        />
      </label>
    </div>
  );
}
